import React, { useState, useRef, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import * as moment from "moment-timezone";

import Layout from "../../components/Layout";

import api, { apiCRMv3 } from "../../services/api";

import AsyncSelect from "react-select/async";
import Swal from "sweetalert2";
import Autocomplete from "react-autocomplete";
import CurrencyInput from "react-currency-input";
import { AreaSelector } from "@bmunozg/react-image-area";
import MultiselectTwoSides from "react-multiselect-two-sides";
import {
	R01,
	R02,
	R03,
	R04,
	R05,
	R06,
	R07,
	R08,
	R09,
	R10,
	R11,
	R12,
	R13,
} from "../../services/regionals";

const imageMimeType = /image\/(png|jpg|jpeg)/i;

const DirectMailCRMNew = (props) => {
	const [loaded, setLoaded] = useState(false);
	const [error, setError] = useState({});
	const [directMailFields, setDirectMailFields] = useState(null);
	const [directMailTypeSelected, setDirectMailTypeSelected] = useState("");
	const [name, setName] = useState("");
	const [branches, setBranches] = useState([]);
	const [selectedBranches, setSelectedBranches] = useState([]);

	const [showFieldOptions, setShowFieldOptions] = useState(false);
	const [maxAreas, setMaxAreas] = useState(0);

	const [areas, setAreas] = useState([]);
	const [selectedArea, setSelectedArea] = useState(null);
	const [selectedAreaIndex, setSelectedAreaIndex] = useState(null);
	const [file, setFile] = useState(null);
	const [fileDataURL, setFileDataURL] = useState(null);

	useEffect(() => {
		let fileReader,
			isCancel = false;
		if (file) {
			fileReader = new FileReader();
			fileReader.onload = (e) => {
				const { result } = e.target;
				if (result && !isCancel) {
					setFileDataURL(result);
				}
			};
			fileReader.readAsDataURL(file);
		}
		return () => {
			isCancel = true;
			if (fileReader && fileReader.readyState === 1) {
				fileReader.abort();
			}
		};
	}, [file]);

	useEffect(() => {
		const load = async () => {
			try {
				const clientListsResponse = await apiCRMv3.get(
					"/direct-mails/fields-of-direct-mails"
				);
				setDirectMailFields(
					clientListsResponse.data.data.directMailTypeFields
				);
				const returnBranches = await api.get("/branch");
				// const branches = getBranches();
				// let branchesToSelect = [];
				const branchesFiltered = returnBranches.data.filter(
					(value, index, self) =>
						index ===
						self.findIndex(
							(t) => t.codigo_loja === value.codigo_loja
						)
				);
				const newBranches = await branchesFiltered.map((branch) => {
					if (branch.codigo_loja.indexOf(".") !== -1) {
						return {
							value: branch.codigo_loja.replace(".", "000"),
							label: `${branch.codigo_loja.replace(".", "000")} ${
								branch.cidade_estado
							}`,
						};
					} else {
						if (branch.codigo_loja.indexOf("240") !== -1) {
							return {
								value: branch.codigo_loja,
								label: `${branch.codigo_loja} ${branch.cidade_estado}`,
							};
						} else {
							return {
								value: `10${branch.codigo_loja}`,
								label: `10${branch.codigo_loja} ${branch.cidade_estado}`,
							};
						}
					}
				});
				setBranches(newBranches);
			} catch (error) {
			} finally {
				setLoaded(true);
			}
		};
		load();
	}, []);

	const regionalSelected = (e, regional) => {
		e.preventDefault();
		let branches = selectedBranches;
		switch (regional) {
			case "R01":
				branches = branches.concat(R01);
				break;
			case "R02":
				branches = branches.concat(R02);
				break;
			case "R03":
				branches = branches.concat(R03);
				break;
			case "R04":
				branches = branches.concat(R04);
				break;
			case "R05":
				branches = branches.concat(R05);
				break;
			case "R06":
				branches = branches.concat(R06);
				break;
			case "R07":
				branches = branches.concat(R07);
				break;
			case "R08":
				branches = branches.concat(R08);
				break;
			case "R09":
				branches = branches.concat(R09);
				break;
			case "R10":
				branches = branches.concat(R10);
				break;
			case "R11":
				branches = branches.concat(R11);
				break;
			case "R12":
				branches = branches.concat(R12);
				break;
			case "R13":
				branches = branches.concat(R13);
				break;

			default:
				break;
		}
		setSelectedBranches(branches);
	};

	const handleChangeSelectedBranches = (value) => {
		setSelectedBranches(value);
	};

	function handleCancelButton(event) {
		Swal.fire({
			title: "Tem certeza que deseja cancelar?",
			text: "Cancelar fará você perder todas as modificações feitas até o momento!",
			type: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Sim, quero cancelar!",
			cancelButtonText: "Cancelar",
		}).then((result) => {
			if (result.value) {
				Swal.fire(
					"Cancelado!",
					"Você irá voltar para a tela de listagem.",
					"success"
				);

				props.history.push("/malas-diretas-crm");
			}
		});
	}

	async function handleSubmitForm(event) {
		event.preventDefault();
		setLoaded(false);
		try {
			if (selectedBranches.length <= 0) {
				Swal.fire({
					type: "error",
					title: "Ops!",
					text: "Você não selecionou nenhuma filial. É necessário selecionar pelo menos uma filial!",
				});
				setLoaded(true);
				return;
			}
			const formData = new FormData();
			formData.append("name", name);
			formData.append("type", directMailTypeSelected);
			formData.append("background", file);
			formData.append(
				"fields",
				JSON.stringify(directMailFields[directMailTypeSelected])
			);
			formData.append("fieldsValues", JSON.stringify(areas));
			for (const selectedBranch of selectedBranches) {
				formData.append("branches[]", selectedBranch);
			}
			const createdDirectMail = await apiCRMv3.post(
				"/direct-mails",
				formData
			);
			setLoaded(true);
			if (createdDirectMail.status === 201) {
				setLoaded(true);

				Swal.fire({
					type: "success",
					title: "Salvo!",
					text: "A mala direta foi salva com sucesso!",
				}).then(() => props.history.push("/malas-diretas-crm"));
			} else {
				Swal.fire({
					type: "error",
					title: "Oops...",
					text: "Tivemos um problema ao salvar essa nova mala direta. Contate o suporte!",
				});

				setLoaded(true);
			}
		} catch (e) {
			Swal.fire({
				type: "error",
				title: "Oops...",
				text: "Tivemos um problema ao salvar essa nova mala direta. Contate o suporte!",
			});
		} finally {
			setLoaded(true);
		}
	}

	const onChangeHandlerFileBackground = (e) => {
		const file = e.target.files[0];
		if (!file.type.match(imageMimeType)) {
			alert("Image mime type is not valid");
			return;
		}
		setFile(file);
	};

	const onChangeHandler = (areas) => {
		let cleanedAreas = areas.filter((area) => area.fieldName !== undefined);
		setAreas(cleanedAreas);
	};

	const customRender = (areaProps) => {
		if (!areaProps.isChanging) {
			return (
				<div
					key={areaProps.areaNumber}
					onClick={() => {
						const indexArea = areas.findIndex(
							(obj) => obj.fieldName === areaProps.fieldName
						);
						if (indexArea === -1) {
						}
						setShowFieldOptions(true);
						setSelectedArea(areaProps);
						setSelectedAreaIndex(indexArea);
					}}
				>
					<h1>{areaProps.fieldLabel}</h1>
				</div>
			);
		}
	};

	const removeArea = () => {
		const array = areas.filter(
			(area) => area.fieldName !== selectedArea.fieldName
		);
		setAreas(array);
		setShowFieldOptions(false);
		setSelectedArea(null);
		setSelectedAreaIndex(null);
	};

	return (
		<Layout
			menuActive="registers"
			pageTitle="Malas Diretas CRM - Nova Mala Direta"
		>
			<div className="row">
				<div className="col-xs-12">
					<div className="card">
						<div className="card-header">
							<div className="card-title">
								Malas Diretas CRM - Nova Mala Direta
							</div>
						</div>
						<div
							className={`card-body ${loaded ? "" : "__loading"}`}
						>
							<div className="loader-container text-center">
								<div className="icon">
									<div className="sk-wave">
										<div className="sk-rect sk-rect1"></div>
										<div className="sk-rect sk-rect2"></div>
										<div className="sk-rect sk-rect3"></div>
										<div className="sk-rect sk-rect4"></div>
										<div className="sk-rect sk-rect5"></div>
									</div>
								</div>
								<div className="title">Carregando</div>
							</div>

							<form
								className="form form-horizontal"
								onSubmit={handleSubmitForm}
							>
								<div className="section">
									<div className="section-title">
										Informações Básicas
									</div>
									<div className="section-body">
										<div
											className={
												"form-group " +
												(error.name ? "has-error" : "")
											}
										>
											<label className="col-md-3 control-label">
												Nome
											</label>
											<div className="col-md-9">
												<input
													type="text"
													className="form-control"
													placeholder=""
													value={name}
													onChange={(event) => {
														setName(
															event.target.value
														);
													}}
												/>
											</div>
										</div>
										<br />
										<div className="form-group">
											<label className="col-md-3 control-label">
												Regionais
											</label>
											<div className="col-md-9">
												<button
													className="btn btn-primary"
													onClick={(e) =>
														regionalSelected(
															e,
															"R01"
														)
													}
												>
													R01
												</button>
												<button
													className="btn btn-primary"
													style={{
														marginLeft: 10,
													}}
													onClick={(e) =>
														regionalSelected(
															e,
															"R02"
														)
													}
												>
													R02
												</button>
												<button
													className="btn btn-primary"
													style={{
														marginLeft: 10,
													}}
													onClick={(e) =>
														regionalSelected(
															e,
															"R03"
														)
													}
												>
													R03
												</button>
												<button
													className="btn btn-primary"
													style={{
														marginLeft: 10,
													}}
													onClick={(e) =>
														regionalSelected(
															e,
															"R04"
														)
													}
												>
													R04
												</button>
												<button
													className="btn btn-primary"
													style={{
														marginLeft: 10,
													}}
													onClick={(e) =>
														regionalSelected(
															e,
															"R05"
														)
													}
												>
													R05
												</button>
												<button
													className="btn btn-primary"
													style={{
														marginLeft: 10,
													}}
													onClick={(e) =>
														regionalSelected(
															e,
															"R06"
														)
													}
												>
													R06
												</button>
												<button
													className="btn btn-primary"
													style={{
														marginLeft: 10,
													}}
													onClick={(e) =>
														regionalSelected(
															e,
															"R07"
														)
													}
												>
													R07
												</button>
												<button
													className="btn btn-primary"
													style={{
														marginLeft: 10,
													}}
													onClick={(e) =>
														regionalSelected(
															e,
															"R08"
														)
													}
												>
													R08
												</button>
												<button
													className="btn btn-primary"
													style={{
														marginLeft: 10,
													}}
													onClick={(e) =>
														regionalSelected(
															e,
															"R09"
														)
													}
												>
													R09
												</button>
												<button
													className="btn btn-primary"
													style={{
														marginLeft: 10,
													}}
													onClick={(e) =>
														regionalSelected(
															e,
															"R10"
														)
													}
												>
													R10
												</button>
												<button
													className="btn btn-primary"
													style={{
														marginLeft: 10,
													}}
													onClick={(e) =>
														regionalSelected(
															e,
															"R11"
														)
													}
												>
													R11
												</button>
												<button
													className="btn btn-primary"
													style={{
														marginLeft: 10,
													}}
													onClick={(e) =>
														regionalSelected(
															e,
															"R12"
														)
													}
												>
													R12
												</button>
												<button
													className="btn btn-primary"
													onClick={(e) =>
														regionalSelected(
															e,
															"R13"
														)
													}
												>
													R13
												</button>
											</div>
										</div>
										<div className="col-md-offset-3 col-md-9">
											<div
												class="alert alert-warning"
												role="alert"
											>
												Para filtrar por estado, basta
												utilizar, por exemplo: "-PR" ou
												"-GO" e clicar na seta para
												enviar todas as filiais
												filtradas do lado esquerdo para
												o direito
											</div>
										</div>
										<div className="form-group">
											<label className="col-md-3 control-label">
												Filiais
											</label>
											<div className="col-md-9">
												<MultiselectTwoSides
													options={branches}
													value={selectedBranches}
													className="msts_theme_example"
													onChange={
														handleChangeSelectedBranches
													}
													availableHeader="Disponíveis"
													availableFooter={`Disponíveis: ${
														branches.length -
														selectedBranches.length
													}`}
													selectedHeader="Selecionadas"
													selectedFooter={`Selecionadas: ${selectedBranches.length}`}
													labelKey="label"
													showControls
													searchable
												/>
											</div>
										</div>
										<br />
										<div
											className={
												"form-group " +
												(error.name ? "has-error" : "")
											}
										>
											<label className="col-md-3 control-label">
												Tipo
											</label>
											<div className="col-md-9">
												<select
													name="directMailType"
													id="directMailTypeId"
													className="form-control"
													onChange={(event) => {
														const { value } =
															event.target;
														setDirectMailTypeSelected(
															value
														);
														setMaxAreas(
															directMailFields[
																value
															].length
														);
													}}
													disabled={
														directMailTypeSelected
													}
												>
													<option value="">
														Escolha um tipo
													</option>
													<option value="DEFAULT">
														Padrão
													</option>
													<option value="DEBT_NEGOTIATION">
														Negociação de Dívida
													</option>
												</select>
											</div>
										</div>
										<br />
										<div
											className={
												"form-group " +
												(error.file ? "has-error" : "")
											}
										>
											<label className="col-md-3 control-label">
												Imagem de Fundo
											</label>
											<div className="col-md-9">
												<input
													type="file"
													id="image"
													accept=".png, .jpg, .jpeg"
													onChange={
														onChangeHandlerFileBackground
													}
													disabled={file}
												/>
											</div>
										</div>
										<br />
										<div
											className="col-md-3"
											style={{
												height: "100%",
											}}
										>
											{directMailFields &&
											directMailTypeSelected
												? directMailFields[
														directMailTypeSelected
												  ].map(
														(
															directMailField,
															indexDirectMailField
														) => (
															<div
																key={
																	indexDirectMailField
																}
															>
																<button
																	onClick={(
																		e
																	) => {
																		e.preventDefault();
																		setAreas(
																			[
																				...areas,
																				{
																					x: 100,
																					y: 100,
																					width: 150,
																					height: 150,
																					unit: "px",
																					isNew: true,
																					isChanging: false,
																					fieldLabel:
																						directMailField.label,
																					fieldName:
																						directMailField.name,
																					fontName:
																						"GothamRoundedMedium",
																					fontSize: 20,
																					fontColor:
																						"#fff",
																					center: false,
																					isFillable:
																						directMailField.isFillable,
																					value: directMailField.isFillable
																						? ""
																						: undefined,
																				},
																			]
																		);
																	}}
																	className="btn btn-primary"
																	style={{
																		width: "100%",
																	}}
																>
																	{
																		directMailField.label
																	}
																</button>
																<br />
															</div>
														)
												  )
												: null}
											{showFieldOptions ? (
												<>
													<label htmlFor="fontName">
														Fonte
													</label>
													<select
														id="fontName"
														className="form-control"
														onChange={(event) => {
															const { value } =
																event.target;
															const areasArray = [
																...areas,
															];
															const objIndex =
																areasArray.findIndex(
																	(obj) =>
																		obj.fieldName ===
																		selectedArea.fieldName
																);
															areasArray[
																objIndex
															].fontName = value;
															setAreas(
																areasArray
															);
														}}
													>
														<option value="">
															Selecione uma fonte
														</option>
														<option
															selected={
																areas[
																	selectedAreaIndex
																].fontName ===
																"GothamRoundedMedium"
															}
															value="GothamRoundedMedium"
														>
															Gotham Rounded
															Medium
														</option>
														<option
															selected={
																areas[
																	selectedAreaIndex
																].fontName ===
																"HelveticaNeueLTStdBlkCn"
															}
															value="HelveticaNeueLTStdBlkCn"
														>
															Helvetica Neue LT
															Standard Black
															Condensed
														</option>
														<option
															selected={
																areas[
																	selectedAreaIndex
																].fontName ===
																"HelveticaNeueLTStdCn"
															}
															value="HelveticaNeueLTStdCn"
														>
															Helvetica Neue LT
															Standard Condensed
														</option>
													</select>
													<br />
													<label htmlFor="fontSize">
														Tamanho da Fonte
													</label>
													<input
														id="fontSize"
														type="number"
														className="form-control"
														value={
															areas[
																selectedAreaIndex
															].fontSize
														}
														onChange={(event) => {
															const { value } =
																event.target;
															const areasArray = [
																...areas,
															];
															areasArray[
																selectedAreaIndex
															].fontSize = value;
															setAreas(
																areasArray
															);
														}}
													/>
													<br />
													<label htmlFor="fontColor">
														Cor da Fonte
													</label>
													<input
														id="fontColor"
														type="text"
														className="form-control"
														value={
															areas[
																selectedAreaIndex
															].fontColor
														}
														onChange={(event) => {
															const { value } =
																event.target;
															const areasArray = [
																...areas,
															];
															areasArray[
																selectedAreaIndex
															].fontColor = value;
															setAreas(
																areasArray
															);
														}}
													/>
													<br />
													<div className="checkbox">
														<input
															type="checkbox"
															id="center"
															checked={
																areas[
																	selectedAreaIndex
																].center
															}
															onChange={(e) => {
																const {
																	checked,
																} = e.target;

																console.log(
																	checked
																);
																const areasArray =
																	[...areas];
																areasArray[
																	selectedAreaIndex
																].center =
																	!areasArray[
																		selectedAreaIndex
																	].center;
																setAreas(
																	areasArray
																);
															}}
														/>
														<label htmlFor="center">
															Texto Centralizado
														</label>
													</div>
													<br />
													{selectedArea.isFillable ? (
														<>
															<label htmlFor="value">
																{
																	selectedArea.fieldLabel
																}
															</label>
															<input
																id="value"
																type="text"
																className="form-control"
																value={
																	areas[
																		selectedAreaIndex
																	].value
																}
																onChange={(
																	event
																) => {
																	const {
																		value,
																	} =
																		event.target;
																	const areasArray =
																		[
																			...areas,
																		];
																	areasArray[
																		selectedAreaIndex
																	].value =
																		value;
																	setAreas(
																		areasArray
																	);
																}}
															/>
															<br />
														</>
													) : null}
													<button
														className="btn btn-danger"
														onClick={() => {
															removeArea();
														}}
													>
														Remover campo
													</button>
												</>
											) : null}
										</div>
										<div className="col-md-9">
											<AreaSelector
												areas={areas}
												onChange={onChangeHandler}
												customAreaRenderer={
													customRender
												}
												maxAreas={maxAreas}
											>
												{fileDataURL ? (
													<img
														src={fileDataURL}
														alt="preview"
													/>
												) : null}
											</AreaSelector>
										</div>
									</div>
								</div>
								<div className="form-footer">
									<div className="form-group">
										<div className="col-md-9 col-md-offset-3">
											<button
												type="submit"
												className="btn btn-primary"
											>
												Salvar
											</button>
											<button
												type="button"
												onClick={handleCancelButton}
												className="btn btn-default"
											>
												Cancelar
											</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default withRouter(DirectMailCRMNew);
