import React, { useState, useEffect } from "react";

import { Link, withRouter } from "react-router-dom";

import Layout from "../../components/Layout";

import { apiCRMv3 } from "../../services/api";

import Swal from "sweetalert2";

const ClientListDirectMailTabloidOrProductList = (props) => {
	const [
		clientListDirectMailTabloidsOrProductsItems,
		setClientListDirectMailTabloidsOrProductsItems,
	] = useState([]);
	const [loaded, setLoaded] = useState(false);
	const [searchText, setSearchText] = useState("");
	const [pages, setPages] = useState(0);
	const [actualPage, setActualPage] = useState(1);

	useEffect(function () {
		const load = async () => {
			setLoaded(false);
			await loadItems();
			setLoaded(true);
		};
		load();
	}, []);

	const loadItems = async (page = 1, search = "") => {
		const clientListDirectMailTabloidsOrProductsItemsResponse =
			await apiCRMv3.get(`/clients-list-direct-mail-tabloid-or-product`, {
				params: { page, search },
			});
		setClientListDirectMailTabloidsOrProductsItems(
			clientListDirectMailTabloidsOrProductsItemsResponse.data.data
				.clientsListDirectMailTabloids
		);
		setPages(
			Math.ceil(
				clientListDirectMailTabloidsOrProductsItemsResponse.data.data
					.clientsListDirectMailTabloidsCount / 10
			)
		);
		setActualPage(page);
	};

	const handleSearchBar = () => {
		const load = async () => {
			setLoaded(false);
			await loadItems(1, searchText);
			setLoaded(true);
		};
		load();
	};

	const handleDelete = async (itemDelete) => {
		Swal.fire({
			title: `Tem certeza que deseja excluir a ligação da lista ativa?`,
			text: "Não será possível voltar atrás!",
			type: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Sim, quero excluir!",
			cancelButtonText: "Cancelar",
		}).then(async (result) => {
			if (!result.value) {
				Swal.fire(
					"Cancelado!",
					"Nenhum dado será excluído.",
					"success"
				);
			} else {
				setLoaded(false);

				try {
					const responseDelete = await apiCRMv3.delete(
						`/clients-list-direct-mail-tabloid-or-product/${itemDelete.id}`
					);
					if (responseDelete.status === 204) {
						await loadItems(1, searchText);
						setLoaded(true);
					} else {
						throw "Error";
					}
				} catch (error) {
					setLoaded(true);
					Swal.fire({
						type: "error",
						title: "Oops...",
						text: "Tivemos um problema ao excluir esse vídeo do aprenda mais. Contate o suporte!",
					});
				}
			}
		});
	};

	return (
		<Layout
			menuActive="registers"
			pageTitle="Funil de Vendas"
			enableSearchBar={true}
			searchTerm={searchText}
			searchOnChange={(e) => setSearchText(e.target.value)}
			handleSearch={handleSearchBar}
		>
			<div className="row">
				<div className="col-xs-12">
					<div className="card">
						<div className="card-header">
							<div className="card-title">Funil de Vendas</div>
							<ul className="card-action">
								<li>
									<Link
										to="/funil-vendas/novo"
										className="btn btn-sm btn-success"
									>
										Nova Ligação{" "}
										<i className="fa fa-plus"></i>
									</Link>
								</li>
							</ul>
						</div>
						<div
							className={`card-body ${loaded ? "" : "__loading"}`}
						>
							<div class="loader-container text-center">
								<div class="icon">
									<div class="sk-wave">
										<div class="sk-rect sk-rect1"></div>
										<div class="sk-rect sk-rect2"></div>
										<div class="sk-rect sk-rect3"></div>
										<div class="sk-rect sk-rect4"></div>
										<div class="sk-rect sk-rect5"></div>
									</div>
								</div>
								<div class="title">Carregando</div>
							</div>

							<div className="row">
								<div className="col-xs-12">
									<table className="table table-striped table-bordered table-hover">
										<thead>
											<tr>
												<th>Lista Ativa</th>
												<th>Mala Direta Simples</th>
												<th>Mala Direta Tabloide</th>
												<th>Produto</th>
												<th>Opções</th>
											</tr>
										</thead>
										<tbody>
											{clientListDirectMailTabloidsOrProductsItems.map(
												(
													clientListDirectMailTabloidsOrProductsItem,
													index
												) => {
													return (
														<tr key={index}>
															<td>
																{
																	clientListDirectMailTabloidsOrProductsItem.listName
																}
															</td>
															<td>
																{
																	clientListDirectMailTabloidsOrProductsItem
																		.directMailTypeDefault
																		?.name
																}
															</td>
															<td>
																{
																	clientListDirectMailTabloidsOrProductsItem.directMailTabloidName
																}
															</td>
															<td>
																{
																	clientListDirectMailTabloidsOrProductsItem
																		.product
																		?.name
																}
															</td>
															<td>
																<div className="col-xs-12">
																	<div className="col-xs-12">
																		<button
																			className="btn btn-xs btn-danger"
																			style={{
																				width: "100%",
																			}}
																			disabled={
																				!clientListDirectMailTabloidsOrProductsItem.status
																			}
																			onClick={() =>
																				handleDelete(
																					clientListDirectMailTabloidsOrProductsItem
																				)
																			}
																		>
																			Apagar{" "}
																			<div className="icon fa fa-trash"></div>
																		</button>
																	</div>
																</div>
															</td>
														</tr>
													);
												}
											)}
										</tbody>
									</table>
								</div>
							</div>
							{pages !== 0 ? (
								<div className="row">
									<div className="col-xs-12">
										<ul className="pagination">
											{Array(pages)
												.fill()
												.map((_, page) => (
													<li
														className={
															page + 1 ===
															actualPage
																? "active"
																: ""
														}
													>
														<a
															href="#"
															onClick={(e) => {
																e.preventDefault();
																loadItems(
																	page + 1,
																	searchText
																);
															}}
														>
															{page + 1}
														</a>
													</li>
												))}
										</ul>
									</div>
								</div>
							) : null}
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default withRouter(ClientListDirectMailTabloidOrProductList);
