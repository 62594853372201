import React, { useState, useRef, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import * as moment from "moment-timezone";

import Layout from "../../components/Layout";

import api, { apiCRMv3 } from "../../services/api";

import AsyncSelect from "react-select/async";
import Swal from "sweetalert2";
import Autocomplete from "react-autocomplete";
import CurrencyInput from "react-currency-input";

const ClientListDirectMailTabloidOrProductNew = (props) => {
	const [loaded, setLoaded] = useState(false);
	const [error, setError] = useState({});
	const [directMailsTypeDefault, setDirectMailsTypeDefault] = useState([]);
	const [directMailTypeDefaultId, setDirectMailTypeDefaultId] =
		useState(null);
	const [directMailTypeDefaultName, setDirectMailTypeDefaultName] =
		useState(null);
	const [directMailTabloids, setDirectMailTabloids] = useState([]);
	const [directMailTabloidId, setDirectMailTabloidId] = useState(null);
	const [directMailTabloidName, setDirectMailTabloidName] = useState(null);
	const [clientLists, setClientLists] = useState([]);
	// const [clientListId, setClientListId] = useState(null);
	const [grouperListId, setGrouperListId] = useState(null);
	const [clientListName, setClientListName] = useState(null);
	const [products, setProducts] = useState([]);
	const [productSelected, setProductSelected] = useState(null);
	const [paymentX, setPaymentX] = useState(10);
	const [sightPrice, setSightPrice] = useState(0);
	const [totalAmount, setTotalAmount] = useState(0);
	const [interest, setInterest] = useState(false);
	const [entrance, setEntrance] = useState(false);
	const [creditCard, setCreditCard] = useState(false);
	const [carne, setCarne] = useState(false);
	const [validity, setValidity] = useState(moment().format("YYYY-MM-DD"));
	const [sellerCard, setSellerCard] = useState(false);

	useEffect(() => {
		const load = async () => {
			try {
				const clientListsResponse = await apiCRMv3.get(
					"/clients-list/list-of-list-names?allUsers=true"
				);
				setClientLists(clientListsResponse.data.data.listOfListNames);
				const directMailTabloidsResponse = await api.get(
					"/direct-mail-tabloid"
				);
				setDirectMailTabloids(
					directMailTabloidsResponse.data.data.directMailTabloids
				);
				const directMailsTypeDefaultResponse = await apiCRMv3.get(
					"/direct-mails-type-default/get"
				);
				setDirectMailsTypeDefault(
					directMailsTypeDefaultResponse.data.data
						.directMailsTypeDefault
				);
			} catch (error) {
			} finally {
				setLoaded(true);
			}
		};
		load();
	}, []);

	const searchProduct = async (inputValue) => {
		console.log(inputValue);
		const searchProductByNameOrCodeResponse = await apiCRMv3.get(
			`/products/search-by-name-or-code?name=${inputValue}`
		);
		const { products } = searchProductByNameOrCodeResponse.data.data;
		return products.map((product) => ({
			label: product.name,
			value: product.id,
		}));
	};

	function handleCancelButton(event) {
		Swal.fire({
			title: "Tem certeza que deseja cancelar?",
			text: "Cancelar fará você perder todas as modificações feitas até o momento!",
			type: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Sim, quero cancelar!",
			cancelButtonText: "Cancelar",
		}).then((result) => {
			if (result.value) {
				Swal.fire(
					"Cancelado!",
					"Você irá voltar para a tela de listagem.",
					"success"
				);

				props.history.push("/funil-vendas");
			}
		});
	}

	async function handleSubmitForm(event) {
		event.preventDefault();
		setLoaded(false);
		try {
			if (!grouperListId) {
				Swal.fire({
					type: "error",
					title: "Ops!",
					text: "Você não escolheu nenhuma lista ativa.",
				});
				return;
			}
			let optionsSelected = 0;
			if (directMailTabloidId) {
				optionsSelected++;
			}
			if (productSelected) {
				optionsSelected++;
			}
			if (directMailTypeDefaultId) {
				optionsSelected++;
			}
			if (optionsSelected > 1) {
				Swal.fire({
					title: "Ops",
					text: "Você deve escolher apenas um: Mala Direta Simples, Mala Direta Tablóide ou Produto.",
					type: "error",
				});
				return;
			}
			if (
				!directMailTabloidId &&
				!productSelected &&
				!directMailTypeDefaultId
			) {
				Swal.fire({
					type: "error",
					title: "Ops!",
					text: "Você não escolheu uma opção (produto, mala direta simples e mala direta tablóide). É necessário escolher um dos três.",
				});
				return;
			}
			let clientListDirectMailTabloidOrProductToSend = {
				clientListGrouperListId: grouperListId,
				clientListName: clientListName,
				validity: validity,
				sellerCard: sellerCard,
			};
			if (directMailTabloidId) {
				clientListDirectMailTabloidOrProductToSend[
					"directMailTabloidId"
				] = directMailTabloidId;
				clientListDirectMailTabloidOrProductToSend[
					"directMailTabloidName"
				] = directMailTabloidName;
			}
			if (directMailTypeDefaultId) {
				clientListDirectMailTabloidOrProductToSend[
					"directMailTypeDefaultId"
				] = directMailTypeDefaultId;
				clientListDirectMailTabloidOrProductToSend[
					"directMailTypeDefaultName"
				] = directMailTypeDefaultName;
			}
			if (productSelected) {
				clientListDirectMailTabloidOrProductToSend["productId"] =
					productSelected.value;
				clientListDirectMailTabloidOrProductToSend["paymentX"] =
					paymentX;
				clientListDirectMailTabloidOrProductToSend["sightPrice"] =
					sightPrice;
				clientListDirectMailTabloidOrProductToSend["totalAmount"] =
					totalAmount;
				clientListDirectMailTabloidOrProductToSend["interest"] =
					interest;
				clientListDirectMailTabloidOrProductToSend["entrance"] =
					entrance;
				clientListDirectMailTabloidOrProductToSend["creditCard"] =
					creditCard;
				clientListDirectMailTabloidOrProductToSend["carne"] = carne;
			}
			console.log(clientListDirectMailTabloidOrProductToSend);
			const createdClientListDirectMailTabloidOrProduct =
				await apiCRMv3.post(
					"/clients-list-direct-mail-tabloid-or-product",
					clientListDirectMailTabloidOrProductToSend
				);
			if (createdClientListDirectMailTabloidOrProduct.status === 201) {
				Swal.fire({
					type: "success",
					title: "Salvo!",
					text: "A ligação entre lista ativa e mala direta (simples/tabloide)/produto foi salva com sucesso!",
				}).then(() => props.history.push("/funil-vendas"));
			} else {
				Swal.fire({
					type: "error",
					title: "Oops...",
					text: "Tivemos um problema ao salvar a ligação entre lista ativa e mala direta (simples/tabloide)/produto. Contate o suporte!",
				});
			}
		} catch (e) {
			Swal.fire({
				type: "error",
				title: "Oops...",
				text: "Tivemos um problema ao salvar a ligação entre lista ativa e mala direta (simples/tabloide)/produto. Contate o suporte!",
			});
		} finally {
			setLoaded(true);
		}
	}

	return (
		<Layout
			menuActive="registers"
			pageTitle="Funil de Vendas - Nova Ligação"
		>
			<div className="row">
				<div className="col-xs-12">
					<div className="card">
						<div className="card-header">
							<div className="card-title">
								Funil de Vendas - Nova Ligação
							</div>
						</div>
						<div
							className={`card-body ${loaded ? "" : "__loading"}`}
						>
							<div className="loader-container text-center">
								<div className="icon">
									<div className="sk-wave">
										<div className="sk-rect sk-rect1"></div>
										<div className="sk-rect sk-rect2"></div>
										<div className="sk-rect sk-rect3"></div>
										<div className="sk-rect sk-rect4"></div>
										<div className="sk-rect sk-rect5"></div>
									</div>
								</div>
								<div className="title">Carregando</div>
							</div>

							<form
								className="form form-horizontal"
								onSubmit={handleSubmitForm}
							>
								<div className="section">
									<div className="section-title">
										Informações Básicas
									</div>
									<div className="section-body">
										<div
											className={
												"form-group " +
												(error.name ? "has-error" : "")
											}
										>
											<label className="col-md-3 control-label">
												Lista Ativa
											</label>
											<div className="col-md-9">
												<select
													name="grouperListId"
													id="grouperListId"
													className="form-control"
													onChange={(event) => {
														const { value } =
															event.target;
														const valueSplit =
															value.split("@");
														setGrouperListId(
															valueSplit[0]
														);
														setClientListName(
															valueSplit[1]
														);
													}}
												>
													<option value="">
														Escolha uma lista
													</option>
													{clientLists.map(
														(
															clientList,
															clientListIndex
														) => (
															<option
																key={
																	clientListIndex
																}
																value={`${clientList.grouperlistid}@${clientList.listname}`}
																selected={
																	grouperListId ===
																	clientList.grouperListId
																}
															>
																{
																	clientList.listname
																}
															</option>
														)
													)}
												</select>
											</div>
										</div>
										<br />
										<div className="form-group">
											<label className="col-md-3 control-label">
												Validade
											</label>
											<div className="col-md-9">
												<input
													type="date"
													className="form-control"
													placeholder=""
													value={validity}
													onChange={(e) => {
														const { value } =
															e.target;

														setValidity(value);
													}}
												/>
											</div>
										</div>
										<br />
										<div className="checkbox">
											<input
												type="checkbox"
												id="sellerCard"
												checked={sellerCard}
												onChange={(e) => {
													setSellerCard(!sellerCard);
												}}
											/>
											<label htmlFor="sellerCard">
												Gerar Cartão do Vendedor
											</label>
										</div>
									</div>
								</div>
								<div className="section">
									<div className="section-title">Ligação</div>
									<div className="section-body">
										<div
											className={
												"form-group " +
												(error.name ? "has-error" : "")
											}
										>
											<label className="col-md-3 control-label">
												Mala Direta Simples
											</label>
											<div className="col-md-9">
												<select
													name="directMailTypeDefaultId"
													id="directMailTypeDefaultId"
													className="form-control"
													onChange={(event) => {
														const { value } =
															event.target;
														const valueSplit =
															value.split("-");
														setDirectMailTypeDefaultId(
															valueSplit[0]
														);
														setDirectMailTypeDefaultName(
															valueSplit[1]
														);
													}}
												>
													<option value="">
														Escolha uma mala direta
														simples
													</option>
													{directMailsTypeDefault.map(
														(
															directMailTypeDefault,
															directMailTypeDefaultIndex
														) => (
															<option
																key={
																	directMailTypeDefaultIndex
																}
																value={`${directMailTypeDefault.id}-${directMailTypeDefault.name}`}
																selected={
																	directMailTypeDefaultId ===
																	directMailTypeDefault.id
																}
															>
																{
																	directMailTypeDefault.name
																}
															</option>
														)
													)}
												</select>
											</div>
										</div>
										<br />
										<div className="col-xs-12 text-center">
											<hr />
											<small>ou</small>
											<hr />
										</div>
										<br />
										<div
											className={
												"form-group " +
												(error.name ? "has-error" : "")
											}
										>
											<label className="col-md-3 control-label">
												Mala Direta Tabloide
											</label>
											<div className="col-md-9">
												<select
													name="directMailTabloidId"
													id="directMailTabloidId"
													className="form-control"
													onChange={(event) => {
														const { value } =
															event.target;
														const valueSplit =
															value.split("-");
														setDirectMailTabloidId(
															valueSplit[0]
														);
														setDirectMailTabloidName(
															valueSplit[1]
														);
													}}
												>
													<option value="">
														Escolha uma mala direta
														tabloide
													</option>
													{directMailTabloids.map(
														(
															directMailTabloid,
															directMailTabloidIndex
														) => (
															<option
																key={
																	directMailTabloidIndex
																}
																value={`${directMailTabloid._id}-${directMailTabloid.name}`}
																selected={
																	directMailTabloidId ===
																	directMailTabloid._id
																}
															>
																{
																	directMailTabloid.name
																}
															</option>
														)
													)}
												</select>
											</div>
										</div>
										<br />
										<div className="col-xs-12 text-center">
											<hr />
											<small>ou</small>
											<hr />
										</div>
										<br />
										<div className="form-group">
											<label className="col-md-3 control-label">
												Produto
											</label>
											<div className="col-md-9">
												<AsyncSelect
													placeholder="Selecione um produto"
													value={productSelected}
													onChange={(
														selectedOption
													) => {
														setProductSelected(
															selectedOption
														);
													}}
													loadOptions={searchProduct}
												/>
											</div>
										</div>
										<br />
										<div className="form-group">
											<label className="col-md-3 control-label">
												Parcelas
											</label>
											<div className="col-md-9">
												<input
													type="number"
													className="form-control"
													placeholder=""
													value={paymentX}
													onChange={(e) => {
														const { value } =
															e.target;

														setPaymentX(value);
													}}
												/>
											</div>
										</div>

										<div className="form-group">
											<label className="col-md-3 control-label">
												Valor
											</label>
											<div className="col-md-9">
												<div className="input-group">
													<span className="input-group-addon">
														R$
													</span>
													<CurrencyInput
														className="form-control"
														decimalSeparator=","
														thousandSeparator="."
														value={sightPrice}
														onChangeEvent={(
															event,
															maskedvalue,
															floatvalue
														) => {
															setSightPrice(
																floatvalue
															);
														}}
													/>
												</div>
											</div>
										</div>

										<div className="form-group">
											<label className="col-md-3 control-label">
												Valor à Prazo
											</label>
											<div className="col-md-9">
												<div className="input-group">
													<span className="input-group-addon">
														R$
													</span>
													<CurrencyInput
														className="form-control"
														decimalSeparator=","
														thousandSeparator="."
														value={totalAmount}
														onChangeEvent={(
															event,
															maskedvalue,
															floatvalue
														) => {
															setTotalAmount(
																floatvalue
															);
														}}
													/>
												</div>
											</div>
										</div>

										<div className="checkbox">
											<input
												type="checkbox"
												id="interest"
												checked={interest}
												onChange={(e) => {
													setInterest(!interest);
												}}
											/>
											<label htmlFor="interest">
												Sem Juros?
											</label>
										</div>

										<div className="checkbox">
											<input
												type="checkbox"
												id="entrance"
												checked={entrance}
												onChange={(e) => {
													setEntrance(!entrance);
												}}
											/>
											<label htmlFor="entrance">
												Sem Entrada?
											</label>
										</div>

										<div className="checkbox">
											<input
												type="checkbox"
												id="credit_card"
												checked={creditCard}
												onChange={(e) => {
													setCreditCard(!creditCard);
												}}
											/>
											<label htmlFor="credit_card">
												No Cartão de Crédito?
											</label>
										</div>

										<div className="checkbox">
											<input
												type="checkbox"
												id="carne"
												checked={carne}
												onChange={(e) => {
													setCarne(!carne);
												}}
											/>
											<label htmlFor="carne">
												No Carnê?
											</label>
										</div>
									</div>
								</div>
								<div className="form-footer">
									<div className="form-group">
										<div className="col-md-9 col-md-offset-3">
											<button
												type="submit"
												className="btn btn-primary"
											>
												Salvar
											</button>
											<button
												type="button"
												onClick={handleCancelButton}
												className="btn btn-default"
											>
												Cancelar
											</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default withRouter(ClientListDirectMailTabloidOrProductNew);
